var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-medication__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"groupsTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":_vm.itemKey,"headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"item-class":function (item) { return (item.active ? 'v-data-table__selected' : ''); },"data-cy":"medicationsList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("startDate-" + (item.eventId)),attrs:{"disabled":_vm.disabled,"isRequired":true,"label":"dd/mm/yyyy","rules":[_vm.required],"cell-id":item.eventId,"edit-active":item.active},on:{"edit-row":_vm.toggleEdit},model:{value:(item.startDate),callback:function ($$v) {_vm.$set(item, "startDate", $$v)},expression:"item.startDate"}})]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('DatetimePickerCell',{ref:("startTime-" + (item.eventId)),attrs:{"edit-active":item.active,"cell-id":item.eventId,"disabled":_vm.disabled,"label":"00:00 AM","isRequired":true,"type":"time","format":_vm.timeFormat,"rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.startTime),callback:function ($$v) {_vm.$set(item, "startTime", $$v)},expression:"item.startTime"}})]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('SelectCell',{ref:("group-" + (item.eventId)),attrs:{"edit-active":item.active,"items":_vm.sortItems(_vm.types),"label":"Group","editableComponent":'v-autocomplete',"isRequired":true,"rules":[_vm.required],"cell-id":item.eventId,"displayValue":_vm.getDisplayValue(item.group, _vm.types, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.group),callback:function ($$v) {_vm.$set(item, "group", $$v)},expression:"item.group"}})]}},{key:"item.locationProgramId",fn:function(ref){
var item = ref.item;
return [_c('SelectCell',{ref:("siteSection-" + (item.eventId)),attrs:{"edit-active":item.active,"items":_vm.sortItems(_vm.locationProgramItems),"label":"Site-Section","editableComponent":'v-autocomplete',"isRequired":true,"rules":[_vm.required],"cell-id":item.eventId,"displayValue":_vm.getDisplayLocationProgram(item.locationProgramId, _vm.locationProgramItems, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.locationProgramId),callback:function ($$v) {_vm.$set(item, "locationProgramId", $$v)},expression:"item.locationProgramId"}})]}},{key:"item.numAttendees",fn:function(ref){
var item = ref.item;
return [_c('EditableNumberCell',{ref:("num_attendees-" + (item.eventId)),attrs:{"disabled":_vm.disabled,"label":"Num_attendees","isRequired":true,"inputType":"number","cell-id":item.eventId,"edit-active":item.active},on:{"edit-row":_vm.toggleEdit},model:{value:(item.numAttendees),callback:function ($$v) {_vm.$set(item, "numAttendees", $$v)},expression:"item.numAttendees"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("name-" + (item.eventId)),attrs:{"disabled":_vm.disabled,"isRequired":true,"label":"Name","cell-id":item.eventId,"edit-active":item.active},on:{"edit-row":_vm.toggleEdit},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(!item.active && _vm.isDeletable)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()])]}}:null,(!_vm.disabled)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addNewRow}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }