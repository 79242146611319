<template>
    <div class="container flex flex-col">
        <div class="bg-white-pure list-wrapper">
            <div class="flex pb-8 items-center">
                <EpisodesSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="name">Groups</h1>
            </div>
            <div class="flex items-center mb-6">
                <fa-icon icon="filter" class="tablet-hide text-sm text-black mr-4" />
                <LocationProgramFilter
                    class="flex mr-2"
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    v-model="filter.locationProgram"
                    @change="filterChangeHandler"
                />
                <GroupFilter @setGroupFilter="setGroupFilter"/>
                <v-btn
                    depressed
                    data-cy="resetFiltersHandler"
                    class="mr-4"
                    @click="resetFiltersHandler"
                >Reset</v-btn>
            </div>
            <div class="a-episode-list">
                <div class="flex flex-row">
                    <div class="flex items-center w-full">
                        <GroupsTable
                            ref="groupTable"
                            :table-data="events"
                            @events-mutated="refreshEvents"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import GroupsTable from '@/components/groups/GroupsTable'
import GroupFilter from '@/components/groups/GroupFilter'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import camelcaseKeys from 'camelcase-keys'
import GET_GROUPS from '@/graphql/queries/getGroups.gql'
import EpisodesSvg from '@/assets/icons/nav/icons_episodes.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import DateRangePicker from '@/components/partials/DateRangePicker'
import sortItems from '@/components/shared/mixins/sortItems'
import {getInstance} from '@/auth'

const DEFAULT_PAGINATION = {
    offset: 0,
    // limit:
}
const DEFAULT_FILTER = {
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    startDate: null,
    endDate: null,
}
const DEFAULT_SORT = ['startDate desc']
export default {
    name: 'Groups',
    mixins: [BackendHelpers, HttpMixin, ClientHelpers, sortItems],
    components: {GroupsTable, EpisodesSvg, LocationProgramFilter, DateRangePicker, GroupFilter},
    data() {
        return {
            loading: false,
            events: [],
            pagination: {...DEFAULT_PAGINATION},
            sort: [...DEFAULT_SORT],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}

            },
            dateRange: {}
        }
    },
    apollo: {
        events: {
            query: GET_GROUPS,
            variables() {
                const {...pagination} = this.pagination
                const dateRange = this.filter.startDate ? { _gte: this.filter.startDate } && { _lte: this.filter.endDate } : {}
                console.log({dateRange})
                const locationProgramsCompare = this.locationProgramIds && this.locationProgramIds.length ? {
                    _in: this.locationProgramIds
                } : {}
                const authService = getInstance()
                let request = {
                    // ...pagination,
                    tenantId: authService.tenantId,
                    locationProgramsCompare,
                    dateRange
                }
                if(dateRange) {
                    request = {...request, dateRange}
                }
                return request
            },
            result({data: {events, total}}) {
                console.log(events)
                this.events = camelcaseKeys(events || [], {deep: true})
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
    },
    beforeMount() {
        this.loadFilter(this.$route.query)
    },
    methods: {
        refreshEvents() {
            this.$apollo.queries.medications.refetch()
        },
        async setGroupFilter(dataFilter){
            this.filter.startDate = dataFilter.startDate
            this.filter.endDate = dataFilter.endDate
            this.filterChangeHandler()
        },
        loadFilter(query) {
            this.filter.locationProgram = {
                locationId:
                    (!isNaN(query.locationId) && parseInt(query.locationId)) ||
                    DEFAULT_FILTER.locationProgram.locationId,
                locationProgramId:
                    (!isNaN(query.locationProgramId) && parseInt(query.locationProgramId)) ||
                    DEFAULT_FILTER.locationProgram.locationProgramId
            }

            this.filter.startDate = query.startDate || DEFAULT_FILTER.startDate
            this.filter.endDate = query.endDate || DEFAULT_FILTER.endDate

            // this.pagination.limit =
            //     (!isNaN(query.limit) && parseInt(query.limit)) || DEFAULT_PAGINATION.limit
            // this.pagination.offset =
            //     (!isNaN(query.offset) && parseInt(query.offset)) || DEFAULT_PAGINATION.offset

            this.sort = (query.sort && (Array.isArray(query.sort) ? query.sort : [query.sort])) || [...DEFAULT_SORT]
        },
         resetFiltersHandler() {
            this.filter.locationProgram = {
                locationId: null,
                locationProgramId: null
            }
            this.filter.startDate = null
            this.filter.endDate = null
        },
        resetPage() {
            this.pagination.offset = 0
        },
        updateRoute() {
            const query = {
                ...this.filter,
                locationId: this.filter.locationProgram?.locationId,
                locationProgramId: this.filter.locationProgram?.locationProgramId,
                ...this.pagination,
                sort: this.sort.join(',')
            }

            delete query.locationProgram
            delete query.startDate
            delete query.endDate

            this.$router.replace({query}).catch(()=>{});
        },
        filterChangeHandler() {
            this.resetPage()
            this.updateRoute()
        },
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices
        }),
        locationProgramIds() {
            const {locationId, locationProgramId} = this.filter.locationProgram

            if (locationProgramId) return [locationProgramId]
            else if (!locationId) return this.loggedInUser.locationProgramIds

            return this.loggedInUser.locationPrograms
                .filter(({location: {locationId: locId}}) => locId === locationId)
                .map(({locationProgramId}) => locationProgramId)
        },
        episodeStatuses() {
            return this.staticChoices['episode.status']
        },
        workersByLocationProgram() {
            if (!this.filter.locationProgram.locationProgramId) return this.workersByLoggedInUser

            return this.workersByLoggedInUser.filter((worker) =>
                worker.locationProgramIds.includes(this.filter.locationProgram.locationProgramId)
            )
        },
        orderBy() {
            let orderBy = {}
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    let [fieldName, sortDir] = sortOne.split(' ')
                    sortDir = sortDir === 'desc' ? 'desc_nulls_last' : 'asc_nulls_first'

                    switch (fieldName) {
                        case 'locationProgram':
                            sortDir = {
                                location: {
                                    name: sortDir
                                },
                                program: {
                                    name: sortDir
                                }
                            }
                            break
                        case 'client':
                            sortDir = {
                                first_name: sortDir,
                                last_name: sortDir
                            }
                            break
                        case 'worker':
                            sortDir = {
                                first_name: sortDir,
                                last_name: sortDir
                            }
                            break
                    }
                    orderBy[fieldName] = sortDir
                })
            }

            return orderBy
        }
    },
}
</script>
